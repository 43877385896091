import {STATUS} from "../../utils/constants";
import {clientCancelOrder, postClientAction, postClientActionPickup, postWarehouseAction,} from "../../utils/ClientApi";
import ClientDeliveryForm from "./ClientDeliveryForm";
import ClientPartialOrder from "./ClientPartialOrder";
import WhDeclineForm from "./WhDeclineForm";
import WhMakeOrderForm from "./WhMakeOrderForm";
import WhPendingForm from "./WhPendingForm";
import WhDecline from "./WhDecline";
import ReadyForDelivery from "./ReadyForDelivery";
import {useTranslation} from "../../providers";

export const GetStepperOrder = () => {
  const {t} = useTranslation();

  return {
      [STATUS.NEW]: {
        steps: [STATUS.NEW],
        client: {
          // label: "Заказ в ожидании подтверждения складом",
          label: `${t('WAIT_FOR_WAREHOUSE_CONFIRMATION')}`,
          actions: [
            {
              label: `${t('CANCEL_ORDER')}`,
              handler: (orderId) => clientCancelOrder(orderId),
              variant: "contained",
            },
          ],
        },
        wh: {
          label: `${t('NEW_ORDER_RECEIVE')}`,
          component: WhDeclineForm,
        },
      },
      [STATUS.USER_CANCEL]: {
        steps: [STATUS.NEW, STATUS.USER_CANCEL],
        client: {
          label: `${t('ORDER_CANCELLED')}`,
        },
        wh: {
          label: `${t('ORDER_CANCELLED_DISTRIBUTOR')}`,
        },
      },
      [STATUS.WAREHOUSE_DECLINED]: {
        steps: [STATUS.NEW, STATUS.WAREHOUSE_DECLINED],
        client: {
          component: WhDecline,
          // label: "Склад отклонил заказ",
          label: `${t('WAREHOUSE_REJECTED_ORDER')}`,
        },
        wh: {
          component: WhDecline,
          // label: "Заказ отклонён",
          label: `${t('ORDER_REJECTED')}`,
        },
      },
      [STATUS.WAREHOUSE_PENDING]: {
        steps: [STATUS.NEW, STATUS.WAREHOUSE_PENDING],
        client: {
          // label: "Проверка наличия заказа на складе",
          label: `${t('CHECKING_ORDER')}`,
        },
        wh: {
          // label: "Подтверждение наличия заказа",
          label: `${t('CONFIRMATION_ORDER_AVAILABILITY')}`,
          component: WhPendingForm,
          actions: [
            {
              // label: "Подтвердить наличие",
              label: `${t('CONFIRMED_AVAILABILITY')}`,
              handler: (orderId) =>
                  postWarehouseAction(orderId, "checked-accepted"),
              variant: "contained",
            },
            {
              // label: "Подтвердить частичное наличие",
              label: `${t('CONFIRMED_PARTIAL_AVAILABILITY')}`,
              handler: (orderId) => postWarehouseAction(orderId, "checked-change"),
              variant: "contained",
            },
            {
              // label: "Отклонить",
              label: `${t('REJECTED')}`,
              handler: (orderId) => postWarehouseAction(orderId, "cancel"),
            },
          ],
        },
      },
      [STATUS.WAREHOUSE_CHECKED_ACCEPTED]: {
        steps: [
          STATUS.NEW,
          STATUS.WAREHOUSE_PENDING,
          STATUS.WAREHOUSE_CHECKED_ACCEPTED,
          STATUS.DONE,
        ],
        client: {
          // label: "Склад подтвердил наличие заказа",
          label: `${t('WAREHOUSE_CONFIRMED_AVAILABILITY')}`,
        },
        wh: {
          // label: "Подтверждено наличие заказа",
          label: `${t('CONFIRMED_ORDER_AVAILABILITY')}`,
          component: WhMakeOrderForm,
          actions: [
            {
              // label: "Оформить заказ",
              label: `${('PLACE_ORDER')}`,
              handler: (orderId) => postWarehouseAction(orderId, "ready"),
              variant: "contained",
            },
          ],
        },
      },
      [STATUS.WAREHOUSE_CHECKED_DECLINED]: {
        steps: [
          STATUS.NEW,
          STATUS.WAREHOUSE_PENDING,
          STATUS.WAREHOUSE_CHECKED_DECLINED,
        ],
        client: {
          // label: "Склад отклонил заказ изза отсутствия товара",
          label: `${t('WAREHOUSE_REJECT_ABSENCE_ITEM')}`,
        },
        wh: {
          // label: "Заказ отклонён: полное отсутствие",
          label: `${t('WAREHOUSE_REJECT_ABSENCE_ITEMS_TOTAL')}`,
        },
      },
      [STATUS.WAREHOUSE_CHECKED_CHANGE]: {
        steps: [
          STATUS.NEW,
          STATUS.WAREHOUSE_PENDING,
          STATUS.WAREHOUSE_CHECKED_CHANGE,
          STATUS.DONE,
        ],
        client: {
          // label: "Склад изменил заказ изза отсутствия товара",
          label: `${t('WAREHOUSE_CHANGED_ORDER')}`,
          component: ClientPartialOrder,
          actions: [
            {
              // label: "Подтвердить",
              label: `${t('CONFIRMED')}`,
              handler: (orderId) => postClientAction(orderId, "accept"),
              variant: "contained",
            },
            {
              // label: "Отклонить",
              label: `${t('REJECTED')}`,
              handler: (orderId) => postClientAction(orderId, "decline"),
            },
          ],
        },
        wh: {
          label: `${t('WAITING_DISTRIBUTOR_CONFIRMATION')}`,
        },
      },
      [STATUS.USER_ACCEPTED]: {
        steps: [
          STATUS.NEW,
          STATUS.WAREHOUSE_PENDING,
          STATUS.WAREHOUSE_CHECKED_CHANGE,
          STATUS.USER_ACCEPTED,
        ],
        client: {
          // label: "Не полный Заказ подтверджден",
          label: `${t('NOT_COMPLETE_ORDER_CONFIRMED')}`,
        },
        wh: {
          // label: "Дистрибьютер подтвердил заказ",
          label: `${t('DISTRIBUTOR_CONFIRMED_DELIVERY')}`,
          component: WhMakeOrderForm,
          actions: [
            {
              // label: "Заказ готов",
              label: `${t('ORDER_READY')}`,
              handler: (orderId) => postWarehouseAction(orderId, "ready"),
              variant: `${t('CHECKING_ORDER')}`,
            },
          ],
        },
      },
      [STATUS.USER_DECLINED]: {
        steps: [
          STATUS.NEW,
          STATUS.WAREHOUSE_PENDING,
          STATUS.WAREHOUSE_CHECKED_CHANGE,
          STATUS.USER_DECLINED,
        ],
        client: {
          // label: "Не полный Заказ отклонён дистрибьютером",
          label: `${t('NOT_COMPLETE_ORDER_REJECTED')}`,
        },
        wh: {
          // label: "Частичный заказ отменён дистрибьютером",
          label: `${t('NOT_COMPLETE_ORDER_CANCELED')}`,
        },
      },
      [STATUS.READY]: {
        steps: [
          STATUS.NEW,
          STATUS.WAREHOUSE_PENDING,
          STATUS.WAREHOUSE_CHECKED_ACCEPTED,
          STATUS.READY,
          STATUS.DONE,
        ],
        client: {
          // label: "Заказ готов к выдаче",
          label: `${t('ORDER_READY_DELIVERY')}`,
          component: ClientDeliveryForm,
          actions: [
            {
              // label: "Запросить доставку",
              label: `${t('REQUESTED_DELIVERY')}`,
              handler: (orderId) => postClientAction(orderId, "ready-delivery"),
              variant: "contained",
            },

            {
              // label: "Самовывоз",
              label: `${t('PICKUP')}`,
              handler: (orderId) => postClientActionPickup(orderId, "pickup"),
              variant: "contained",
              inform: `${t('DELIVERY_PAYMENT_INFO')}`,
            },
          ],
        },
        // wh: {
        //   label: `${t('ORDER_READY_DELIVERY_PICKUP')}`,
        //   actions: [
        //     {
        //       label: `${t('CONFIRMED_DELIVERY_ORDER')}`,
        //       handler: (orderId) => postWarehouseAction(orderId, "done"),
        //       variant: "contained",
        //     },
        //   ],
        // },
        wh: {
          label: `${t('CLIENT_MODE_DELIVERY')}`
        },
      },
      [STATUS.READY_FOR_PICKUP]: {
        steps: [
          STATUS.NEW,
          STATUS.WAREHOUSE_PENDING,
          STATUS.WAREHOUSE_CHECKED_ACCEPTED,
          STATUS.READY,
          STATUS.READY_FOR_PICKUP,
          STATUS.DONE,
        ],
        client: {
          // label: "Склад подготовил заказ",
          label: `${t('WAREHOUSE_PREPARED_ORDER')}`,
        },
        wh: {
          // label: "Дистрибьютер запросил самовывоз",
          label: `${t('DISTRIBUTOR_ASK_PICKUP')}`,
          actions: [
            {
              // label: "Подтвердить самовывоз",
              label: `${t('CONFIRM_PICKUP')}`,
              handler: (orderId) => postWarehouseAction(orderId, "done"),
              variant: "contained",
            }
          ],
        },
      },
      [STATUS.READY_FOR_DELIVERY]: {
        steps: [
          STATUS.NEW,
          STATUS.WAREHOUSE_PENDING,
          STATUS.WAREHOUSE_CHECKED_ACCEPTED,
          STATUS.READY,
          STATUS.READY_FOR_DELIVERY,
          STATUS.DONE,
        ],
        client: {
          // label: "Склад оформляет доставку",
          label: `${t('WAREHOUSE_PROCESSING_DELIVERY')}`,
        },
        wh: {
          // label: "Дистрибьютер запросил доставку",
          label: `${t('DISTRIBUTOR_ASK_DELIVERY')}`,
          component: ReadyForDelivery,
          actions: [
            {
              // label: "Отправлено",
              label: `${t('SENT')}`,
              handler: (orderId) => postWarehouseAction(orderId, "delivery-start"),
              variant: "contained",
            },
          ],
        },
      },
      [STATUS.DELIVERY_IN_PROGRESS]: {
        steps: [
          STATUS.NEW,
          STATUS.WAREHOUSE_PENDING,
          STATUS.WAREHOUSE_CHECKED_ACCEPTED,
          STATUS.READY,
          STATUS.READY_FOR_DELIVERY,
          STATUS.DELIVERY_IN_PROGRESS,
          STATUS.DONE,
        ],
        client: {
          // label: "Заказ отправлен",
          label: `${t('ORDER_SENT')}`,
          component: ReadyForDelivery,
          actions: [
            {
              // label: "Подтвердить получение",
              label: `${t('CONFIRMED_DELIVERY')}`,
              handler: (orderId) => postClientAction(orderId, "delivery-done"),
              variant: "contained",
            }
          ],
        },
        wh: {
          // label: "Заказ доставляется",
          label: `${t('ORDER_DELIVERIES')}`,
        },
      },
      [STATUS.DONE]: {
        steps: [
          STATUS.NEW,
          STATUS.WAREHOUSE_PENDING,
          STATUS.WAREHOUSE_CHECKED_ACCEPTED,
          STATUS.READY,
          STATUS.DONE,
        ],
        client: {
          // label: "Заказ завершен",
          label: `${t('ORDER_COMPLETE')}`,
        },
        wh: {
          // label: "Заказ завершен",
          label: `${t('ORDER_COMPLETE')}`,
        },
      },
  };
};


