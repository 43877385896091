import React, { useState } from "react";
// material
import {
  Grid,
  Button,
  Container,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
// components
import Page from "../components/Page";
import Iconify from "../components/Iconify";
import {
  BlogPostCard,
  BlogPostsSort,
  BlogPostsSearch,
} from "../sections/@dashboard/blog";
// mock
import { getPromotions } from "../../src/utils/ClientApi";
import Carousel from "../utils/Carousel";
import {useTranslation} from "../providers";

// ----------------------------------------------------------------------

const images = [
  {
    id: 1,
    label: 'Giter Perfume',
    imgPath: 'images/image_1.png' },
  {
    id: 2,
    label: 'Giter Perfume',
    imgPath:'images/image_2.png'
  },
  {
    id: 3,
    label: 'Giter Perfume',
    imgPath:'images/image_3.png'
  },
  {
    id: 4,
    label: 'Giter Perfume',
    imgPath:'images/image_4.png'},
];

const imagesMob = [
  {
    id: 1,
    label: 'Giter Perfume',
    imgPath: 'images/1.png' },
  {
    id: 2,
    label: 'Giter Perfume',
    imgPath:'images/2.png'
  },
  {
    id: 3,
    label: 'Giter Perfume',
    imgPath:'images/3.png'
  },
  {
    id: 4,
    label: 'Giter Perfume',
    imgPath:'images/4.png'},
];


const SORT_OPTIONS = [
  { value: "latest", label: "Latest" },
  { value: "popular", label: "Popular" },
  { value: "oldest", label: "Oldest" },
];

let isMobile = window.matchMedia("only screen and (max-width: 480px)").matches;

// ----------------------------------------------------------------------

export default function Promotions() {
  const [isLoading, setIsLoading] = useState(true);
  const {t} = useTranslation();
  const [promotions, setPromotions] = useState("");
  if (promotions === "")
    getPromotions()
      .then((data) => {
        console.log("promotions data", data);
        setPromotions(data);
        setIsLoading(false);
      })
      .catch((err_data) => {
        console.log("no data", err_data);
        // TODO: Make allert!
      });

  return (
    <Page title="Акции">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {t('PROMOTIONS')}
          </Typography>
        </Stack>
        {isMobile ? (<Carousel images={imagesMob}/> ) : <Carousel images={images}/> }
      <div style={{marginTop: "40px", marginBottom: "20px"}}>&nbsp;</div>
      {isLoading ? (
          <CircularProgress />
      ) : (
          <>
            {promotions?.length === 0 ? (
                <Typography variant="h6" pb={1}>
                  {t('NO_RESULTS')}Нет результатов...
                </Typography>
            ) : (
                <>
                  <Grid container spacing={6}>
                    {promotions.map((promotion) => (
                        <BlogPostCard key={promotion.id} promotion={promotion} />
                    ))}
                  </Grid>
                </>
            )}
          </>
      )}
      </Container>
    </Page>
  );
}
