import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
    Stack,
    IconButton,
    InputAdornment,
    Alert,
    Dialog,
    DialogContent,
    TextField,
    DialogActions,
    CircularProgress,
    Box, Modal
} from "@mui/material";
import Button from '@mui/material/Button';
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/Iconify";
import {FormProvider, RHFTextField} from "../../../components/hook-form";

import { userLogin } from "../../../store/authSlice";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import {requestPassword} from "../../../utils/apiCalls";

// ----------------------------------------------------------------------

export default function LoginForm(message) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error, user, loginStatus } = useSelector((state) => state.auth);

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required("Login is required"),
    password: Yup.string().required("Password is required"),
  });

  const defaultValues = {
    email: "",
    password: "",
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onSubmit = async () => {
    const values = methods.getValues();
    dispatch(userLogin(values));
    methods.setValue("email", "");
    methods.setValue("password", "");
  };

  useEffect(() => {
    if (user) {
      navigate("/dashboard", { replace: true });
    }
  });

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClose2 = () => {
        navigate('/');
        setOpen2(false);
    };

    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [showNoSuccessBanner, setShowNoSuccessBanner] = useState(false);
    const [showSuccessBanner, setShowSuccessBanner] = useState(false);
    const [resetEmail, setResetEmail] = useState('');

    const [loading, setLoading] = useState(false);

    return (

    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ my: 2 }}>
        {typeof error === "string" && <Alert severity="error">{error}</Alert>}
        <RHFTextField name="email" label="Email / ЛРН" />
        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      {/* <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <RHFCheckbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack> */}

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={loginStatus === "pending"}
      >
        Login
      </LoadingButton>
        <br/>
        <br/>
        <br/>
            <Button style={{border:"none"}} variant="outlined" onClick={handleClickOpen}>
                Forgot password
            </Button>
            <Dialog
                open={open}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        setLoading(true);
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        const lnr = formJson.lnr;
                        console.log('lnr',lnr);
                        requestPassword(lnr)
                            .then((data) => {
                                setResetEmail(data?.email);
                                setShowSuccessBanner(true);
                                setOpen2(true);
                                console.log('resetEmail', data.email)
                            })
                            .catch((err_data) => {
                                setShowNoSuccessBanner(true);
                                setOpen2(true);
                                console.log("no data", err_data);
                            })
                            .finally(() => {setLoading(false) });
                    },
                }}
            >
                <DialogTitle>Восстановить Пароль</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Введите свой ЛНР.  Мы вышлем ваш пароль на email
                        <br/>
                        <br/>
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="lnr"
                        label="LNR"
                        type="input"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit" onClick={handleClose}>Enter</Button>
                </DialogActions>
            </Dialog>
        <Dialog  open={open2}
                 fullWidth={fullWidth}
                 maxWidth={maxWidth}
                 onClose={handleClose}
        >
            <DialogTitle>Восстановить Пароль</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {showNoSuccessBanner && (
                        <Alert sx={{ mt: 3 }} severity="error">
                            Ваш ЛНР не найден
                        </Alert>
                    )}
                    {showSuccessBanner && (
                        <Alert sx={{ mt: 3 }} severity="success">
                            Ваш пароль выслан на email: {resetEmail}
                        </Alert>)}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose2}>Ok</Button>
            </DialogActions>
        </Dialog>
        {loading ?
            <Box>
                <Modal
                    disablePortal
                    disableEnforceFocus
                    disableAutoFocus
                    open
                    aria-labelledby="server-modal-title"
                    aria-describedby="server-modal-description"
                    sx={{
                        display: 'flex',
                        p: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >

                    <Box
                        sx={{
                            position: 'relative',
                            border: 'none',
                            p: 4,
                        }}
                    >
                        <CircularProgress />
                    </Box>
                </Modal>
            </Box>
            : '' }
    </FormProvider>
  );
}
