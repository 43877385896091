export const dictionary = {
    RU: {
        MAIN: 'Главная',
        MY_NETWORK: 'Моя сеть',
        PRODUCTS: 'Продукты',
        ORDERS: 'Мои заказы',
        ORDERS_WH: 'Заказы к складу',
        CART: 'Корзина',
        PROMOTIONS: 'Акции',
        PROMOTIONS_RATINGS: 'Акции и Рейтинги',
        MARKETING_PLAN: 'Маркетинг План',
        MARKETING_PLAN_URL: 'Маркетинг_план.pdf',
        ETHICS: 'Кодекс этики',
        ETHICS_URL: 'Кодекс_этики.pdf',
        QUALIFICATION: 'Квалификации',
        QUALIFICATION_URL: 'Квалификации.pdf',
        COPY_LINK: 'Скопируйте вашу партнерскую ссылку',
        CLOSED_MONTH:'Закрытый месяц',
        LANG: 'RU',
        BONUSES: 'Бонусы',
        ACCURED_BONUSES: 'Начисленный бонус',
        VOLUMES: 'Объемы',
        PV: 'ЛО',
        GV: 'ГО',
        CGV: 'НГО',
        PGV: 'ОЛГ',
        FOR_11: 'за 11 месяцев',
        QUALIFICATIONS: 'Квалификации',
        COUNTRY: 'Страна',
        TOP_SELLER : 'Лучший продавец',
        RECRUITMENT_LEADER: 'Лидер Рекрутинга',
        STABILITY: 'Стабильность',
        NETWORK: 'Сеть',
        DISTRIBUTORS: 'Дистрибьюторов',
        DISTRIBUTOR: 'Дистрибьютор',
        ACTIVE_DISTRIBUTORS: 'Активные дистрибьюторы',
        QUALIFIED_DISTRIBUTORS: 'Квалифицированные дистрибьюторы',
        LO_MORE_0: '(Л.О. - больше 0 б)',
        PV_GREATER_25P: '(Л.О.- больше или равно 25 б)',
        QUALIFIED_DIRECTORS_1: ' Квалифицированных директоров в 1 поколении',
        DEPTH_LEVEL : 'Уровень глубины квалифицированных директоров',
        NEW_RECRUITS: 'Новичков подписанных в первую линию',
        TOTAL_RECRUITS: 'Всего новичков',
        DOWNLOAD_BONUS: 'Скачать распечатку бонуса',
        CURRENT_MONTH : 'Данные текущего месяца',
        SIGNING_DATE : 'Дата подписания',
        RANG: 'Ранг',
        PRN: 'ЛРН',
        FULL_NAME: 'ФИО',
        EMAIL: 'Почта',
        PHONE: 'Телефон',
        VOLUME: 'Объем',
        NUMBER: 'Номер',
        PRICE: 'Цена',
        QUANTITY: 'Количество',
        QUANTITY_HALF: 'Кол.',
        TOTAL_POINTS: 'Всего баллов',
        TOTAL_ITEMS: 'Всего товаров',
        TOTAL_COST: 'Общая стоимость',
        POINTS: 'Баллы',
        NAME: 'Название',
        MAKEUP: 'Макияж',
        PERFUMERY: 'Парфюмерия',
        FILTERS: 'Фильтры',
        GOODS_1: 'Товар успешно добавлен в корзину',
        GOODS_2: 'Уход за лицом',
        GOODS_3: 'Безбалльные товары',
        GOODS_4: 'Сортировать по:',
        GOODS_5: 'Цена - по возрастанию',
        GOODS_6: 'Цена - по убыванию',
        GOODS_7: 'Баллы - по возрастанию',
        GOODS_8: 'Баллы - по убыванию',
        GOODS_9: 'Нет результатов...',
        GOODS_10: 'Список товаров',
        EMPTY_CART: 'Корзина пуста...',
        PLACE_ORDER: 'Оформить заказ',
        CANCEL: 'Отмена',
        DELETE: 'Удалить',
        CART_1: 'Заказ выполнен! Перенаправление на страницу заказа...',
        CART_2: 'Ошибка при создании заказа',
        CART_3: 'Вы уверены, что хотите удалить товар из корзины?',
        GO_TO_CART: 'Перейти в корзину',
        CHOOSE: 'Выберите...',
        NO_RESULTS: 'Нет результатов...',
        LEARN_MORE: 'Узнать больше',
        MY_ORDERS: 'Мои заказы',
        CURRENT: 'Текущие',
        CANCELED: 'Отмененные',
        CLOSED: 'Закрытые',
        CREATED_DATE: 'Дата создания',
        QUANTITY_ITEMS: 'Количество товаров',
        AMOUNT: 'Сумма',
        STATUS: 'Статус',
        UPDATE_DATE: 'Дата обновления',
        ORDER_NR: 'Заказ Nr.',
        LINK_PAYMENT: 'Ссылка для оплаты',
        ONLINE_PAY: 'Оплата онлайн',
        DISCOUNT: 'Cкидка',
        WAREHOUSE_NR: 'Номер склада',
        WAREHOUSE_TOTAL: 'Тотал склада',
        CUSTOMER: 'Заказчик',
        DELETE_ORDER: 'Удалить заказ',
        WAREHOUSE_ORDER: 'Заказы склада',
        ASK_FOR_DELIVERY: 'Запросить доставку',
        REQUEST: 'Запросить',
        PICKUP: 'Самовывоз',
        CLIENT_MODE_DELIVERY: 'Партнер выбирает способ доставки',
        DELIVERY_ADDRESS: 'Адрес доставки',
        WAIT_FOR_WAREHOUSE_CONFIRMATION: 'Заказ в ожидании подтверждения складом',
        REVERT: 'Отменить',
        CANCEL_ORDER: 'Отменить',
        NEW_ORDER_RECEIVE: 'Новый заказ получен',
        ORDER_CANCELLED: 'Заказ отменен',
        ORDER_CANCELLED_DISTRIBUTOR: 'Заказ отменен дистрибьютором',
        WAREHOUSE_REJECTED_ORDER: 'Склад отклонил заказ',
        ORDER_REJECTED: 'Заказ отклонён',
        CHECKING_ORDER: "Проверка наличия заказа на складе",
        CONFIRMATION_ORDER_AVAILABILITY: "Подтверждение наличия заказа",
        CONFIRMED_AVAILABILITY: "Подтвердить наличие",
        CONFIRMED_PARTIAL_AVAILABILITY: "Подтвердить частичное наличие",
        REJECTED: "Отклонен",
        REJECTED_NEW: "Отклонены",
        WAREHOUSEASSIGNED: "Новые",
        SENDEDTOONEC: "Добавленные в 1С",
        SENDTOONEC: "Добавлен в 1С",
        TOBEREJECTED: "Отклонить",
        MESSAGES: 'Чат',
        WAREHOUSE_CONFIRMED_AVAILABILITY: "Склад подтвердил наличие заказа",
        CONFIRMED_ORDER_AVAILABILITY: "Подтверждено наличие заказа",
        WAREHOUSE_REJECT_ABSENCE_ITEM: "Склад отклонил заказ изза отсутствия товара",
        WAREHOUSE_REJECT_ABSENCE_ITEMS_TOTAL: "Заказ отклонён: полное отсутствие",
        WAREHOUSE_CHANGED_ORDER: "Склад изменил заказ изза отсутствия товара",
        CONFIRMED: "Подтвердить",
        WAITING_DISTRIBUTOR_CONFIRMATION: "Ожадиание подтверждения дистрибьютором измененного заказа",
        NOT_COMPLETE_ORDER_CONFIRMED: "Не полный Заказ подтверджден",
        DISTRIBUTOR_CONFIRMED_DELIVERY: "Дистрибьютор подтвердил заказ",
        ORDER_READY: "Заказ готов",
        NOT_COMPLETE_ORDER_REJECTED:"Не полный Заказ отклонён дистрибьютором",
        NOT_COMPLETE_ORDER_CANCELED: "Частичный заказ отменён дистрибьютором",
        ORDER_READY_DELIVERY: "Заказ готов к выдаче",
        REQUESTED_DELIVERY: "Запросить доставку",
        ORDER_READY_DELIVERY_PICKUP: "Заказ готов к выдаче/доставке",
        CONFIRMED_DELIVERY_ORDER: "Подтвердить выдачу заказа",
        WAREHOUSE_PREPARED_ORDER: "Склад подготовил заказ",
        DISTRIBUTOR_ASK_PICKUP: "Дистрибьютор запросил самовывоз",
        CONFIRM_PICKUP: "Подтвердить самовывоз",
        WAREHOUSE_PROCESSING_DELIVERY: "Склад оформляет доставку",
        DISTRIBUTOR_ASK_DELIVERY: "Дистрибьютор запросил доставку",
        DELIVERY_PAYMENT_INFO: 'Оплата доставки осуществляется отдельно от заказа',
        SENT: "Отправлено",
        ORDER_SENT: "Заказ отправлен",
        CONFIRMED_DELIVERY: "Подтвердить получение",
        ORDER_DELIVERIES: "Заказ доставляется",
        ORDER_COMPLETE: "Заказ завершен",
        REASON: 'Причина',
        ACCEPT: 'Принять',
        REJECT_THE_ORDER: 'Отклонить заказ',
        EDITING_THE_ORDER: 'Редактирование заказа',
        FINAL_COST: 'Финальная стоимость',
        ONLINE_PAYMENT_OPTION:'Возможность оплаты онлайн',
        PAYMENT_LINK: 'Ссылка на оплату',
        ADDRESS: 'Адрес',
        EXIT: 'Выйти',
        DONE: 'Выполнен',
        NEW: 'Новый',
        NEWERS: 'Новички',
        IS_WAITING_BY_WAREHOUSE: 'Ожидание склада',
        CANCELED_BY_WAREHOUSE: 'Отменен складом',
        ACCEPTED_WAREHOUSE: 'Подтвержден складом',
        REJECTED_BY_WAREHOUSE: 'Отклонен складом',
        MODIFIED_BY_WAREHOUSE: 'Изменен складом',
        ACCEPTED_BY_DISTRIBUTOR: 'Принято дистрибьютором',
        CANCELED_BY_DISTRIBUTOR: 'Отменен дистрибьютором',
        READY_TO_PICKUP: 'Готов к выдаче',
        READY_TO_DELIVERY: 'Готов к доставке',
        SELF_PICKUP: 'Самовывоз',
        IS_DELIVERED: 'Доставляется',
    },
    EN: {
        MAIN: 'Main',
        MY_NETWORK: 'My Network',
        PRODUCTS: 'Products',
        ORDERS: 'My orders',
        ORDERS_WH: 'Warehouse orders',
        CART: 'Shopping Bag',
        PROMOTIONS: 'Promotions',
        PROMOTIONS_RATINGS: 'Promotions and Ratings',
        MARKETING_PLAN: 'Marketing Plan',
        MARKETING_PLAN_URL: 'Marketing_plan_ENG.pdf',
        ETHICS: 'Ethics code',
        ETHICS_URL: 'Ethics_code.pdf',
        QUALIFICATION: 'Qualification programs',
        QUALIFICATION_URL: 'Qualification_programs.pdf',
        COPY_LINK: 'Copy your referral link',
        CLOSED_MONTH:'Closed month',
        LANG: 'EN',
        BONUSES: 'Bonuses',
        ACCURED_BONUSES: 'Accrued bonuses',
        VOLUMES: 'Volumes',
        PV: 'PV',
        GV: 'GV',
        CGV: 'CGV',
        PGV: 'PGV',
        FOR_11: 'for 11 month’s',
        QUALIFICATIONS: 'Qualifications',
        COUNTRY: 'Country',
        TOP_SELLER : 'Top seller',
        RECRUITMENT_LEADER: 'Recruitment Leader',
        STABILITY: 'Stability',
        NETWORK: 'Network',
        DISTRIBUTORS: 'Distributors',
        ACTIVE_DISTRIBUTORS: 'Active Distributors',
        QUALIFIED_DISTRIBUTORS: 'Qualification distributors',
        LO_MORE_0: '(P.V. - more than 0 P)',
        PV_GREATER_25P: '(P.V. - greater than or equal to 25 P)',
        QUALIFIED_DIRECTORS_1: 'Qualified directors in 1st generation',
        DEPTH_LEVEL : 'Depth level of qualified directors',
        NEW_RECRUITS: 'New recruits signed in the first line',
        TOTAL_RECRUITS: 'Total recruits',
        DOWNLOAD_BONUS: 'Download bonus printout',
        CURRENT_MONTH : 'Current month\'s data',
        SIGNING_DATE : 'Signing date',
        RANG: ' Rang',
        PRN: 'PRN',
        FULL_NAME: 'Full Name',
        EMAIL: 'Email',
        PHONE: 'Phone',
        VOLUME: 'Volume',
        NUMBER: 'Number',
        PRICE: 'Price',
        TOTAL_POINTS: 'Total Points',
        TOTAL_ITEMS: 'Total Items',
        TOTAL_COST: 'Total Cost',
        POINTS: 'Points',
        NAME: 'Name',
        MAKEUP: 'Makeup',
        PERFUMERY: 'Perfumery',
        FILTERS: 'Filters',
        QUANTITY: 'Quantity',
        QUANTITY_HALF: 'Qty.',
        GOODS_1: 'Item successfully added to cart',
        GOODS_2: 'Facial care',
        GOODS_3: 'Products without points',
        GOODS_4: 'Sort by:',
        GOODS_5: 'Price Low to High',
        GOODS_6: 'Price High to Low',
        GOODS_7: 'Points Low to High',
        GOODS_8: 'Points High to Low',
        GOODS_9: 'No results...',
        GOODS_10: 'Product list',
        EMPTY_CART: 'Empty cart...',
        PLACE_ORDER: 'Place order',
        CANCEL: 'Cancel',
        DELETE: 'Delete',
        CART_1: 'Order completed! Redirecting to the order page...',
        CART_2: 'Error when creating an order',
        CART_3: 'Are you sure you want to delete the item from the cart?',
        GO_TO_CART: 'Go to cart',
        CHOOSE: 'Choose...',
        NO_RESULTS: 'No results...',
        LEARN_MORE: 'Learn more',
        MY_ORDERS: 'My Orders',
        CURRENT: 'Current',
        CANCELED: 'Cancelled',
        CLOSED: 'Closed',
        CREATED_DATE: 'Created date',
        QUANTITY_ITEMS: 'Quantity of items',
        AMOUNT: 'Amount',
        STATUS: 'Status',
        UPDATE_DATE: 'Update date',
        ORDER_NR: 'Order Nr.',
        LINK_PAYMENT: 'Payment link',
        ONLINE_PAY: 'Online payment',
        DISCOUNT: 'Discount',
        WAREHOUSE_NR: 'Warehouse number',
        WAREHOUSE_TOTAL: 'Warehouse total',
        CUSTOMER: 'Customer',
        DELETE_ORDER: 'Delete order',
        WAREHOUSE_ORDER: 'Warehouse order',
        ASK_FOR_DELIVERY: 'Request delivery',
        REQUEST: 'Request',
        PICKUP: 'Self-pickup',
        CLIENT_MODE_DELIVERY: 'The partner chooses the delivery method',
        DELIVERY_ADDRESS: 'Delivery address',
        WAIT_FOR_WAREHOUSE_CONFIRMATION: 'Order awaiting warehouse confirmation',
        CANCEL_ORDER: 'Cancel',
        NEW_ORDER_RECEIVE: 'New order received',
        ORDER_CANCELLED: 'Order cancelled',
        ORDER_CANCELLED_DISTRIBUTOR: 'Order cancelled by distributor',
        WAREHOUSE_REJECTED_ORDER: 'Warehouse has rejected the order',
        ORDER_REJECTED: 'Order rejected',
        CHECKING_ORDER: 'Checking order availability at the warehouse',
        CONFIRMATION_ORDER_AVAILABILITY: 'Confirm availability order',
        CONFIRMED_AVAILABILITY: "Confirm availability",
        CONFIRMED_PARTIAL_AVAILABILITY: "Confirm partial availability",
        REJECTED: "Reject",
        REJECTED_NEW: "Rejected",
        WAREHOUSEASSIGNED: "New",
        SENDEDTOONEC: "Sended to 1C",
        SENDTOONEC: "Send to 1C",
        TOBEREJECTED: "Reject",
        MESSAGES: 'Chat',
        WAREHOUSE_CONFIRMED_AVAILABILITY: "Warehouse confirmed order availability",
        CONFIRMED_ORDER_AVAILABILITY: "Confirmed order availability",
        WAREHOUSE_REJECT_ABSENCE_ITEM: "Warehouse rejected order due to out of stock",
        WAREHOUSE_REJECT_ABSENCE_ITEMS_TOTAL: "Order rejected : out of stock",
        WAREHOUSE_CHANGED_ORDER: "The warehouse modified the order due to out of stock",
        CONFIRMED: "Confirm",
        WAITING_DISTRIBUTOR_CONFIRMATION: "Waiting for distributor confirmation of canceled order",
        NOT_COMPLETE_ORDER_CONFIRMED: "Partial order confirmed",
        DISTRIBUTOR_CONFIRMED_DELIVERY: "Distributor confirmed order",
        ORDER_READY: "Order ready",
        NOT_COMPLETE_ORDER_REJECTED:"Partial order rejected by distributor",
        NOT_COMPLETE_ORDER_CANCELED: "Partial order canceled by distributor",
        ORDER_READY_DELIVERY: "Ready for pickup",
        REQUESTED_DELIVERY: "Request delivery",
        ORDER_READY_DELIVERY_PICKUP: "Ready for pickup/delivery",
        CONFIRMED_DELIVERY_ORDER: "Confirm pickup",
        WAREHOUSE_PREPARED_ORDER: "Warehouse has prepared the order",
        DISTRIBUTOR_ASK_PICKUP: "Distributor request self-pickup",
        CONFIRM_PICKUP: "Confirm self-pickup",
        WAREHOUSE_PROCESSING_DELIVERY: "Warehouse place order",
        DISTRIBUTOR_ASK_DELIVERY: "Distributor delivery request",
        DELIVERY_PAYMENT_INFO: 'Delivery payment is processed separately from the order',
        SENT: "Sent",
        ORDER_SENT: "Order sent",
        CONFIRMED_DELIVERY: "Confirm receipt",
        ORDER_DELIVERIES: "Order is being delivered",
        ORDER_COMPLETE: "Order completed",
        REASON: 'Reason',
        ACCEPT: 'Accept',
        REJECT_THE_ORDER: 'Reject the order',
        EDITING_THE_ORDER: 'Editing the order',
        FINAL_COST: 'Final cost',
        ONLINE_PAYMENT_OPTION:'Online payment option',
        PAYMENT_LINK: 'Payment link',
        ADDRESS: 'Address',
        EXIT: 'Exit',
        DONE: 'Done',
        NEW: 'New',
        NEWERS: 'Newers',
        IS_WAITING_BY_WAREHOUSE: 'Is waiting by warehouse',
        CANCELED_BY_WAREHOUSE: 'Canceled by warehouse',
        ACCEPTED_BY_WAREHOUSE: 'Accepted by warehouse',
        REJECTED_BY_WAREHOUSE: 'Rejected by warehouse',
        MODIFIED_BY_WAREHOUSE: 'Modified by warehouse',
        ACCEPTED_BY_DISTRIBUTOR: 'Accepted by distributor',
        CANCELED_BY_DISTRIBUTOR: 'Canceled by distributor',
        READY_TO_PICKUP: 'Ready to pickup',
        READY_TO_DELIVERY: 'Ready to delivery',
        SELF_PICKUP: 'Self pickup',
        IS_DELIVERING: 'Is delivering',
    },
    RO: {
        MAIN: 'Acasă',
        MY_NETWORK: 'Rețeaua mea',
        PRODUCTS: 'Produse',
        ORDERS: 'Comenzile mele',
        ORDERS_WH: 'Comenzile depozitului',
        CART: 'Coș',
        PROMOTIONS: 'Promotiile',
        PROMOTIONS_RATINGS: "Promoții și Evaluări",
        MARKETING_PLAN: 'Marketing plan',
        MARKETING_PLAN_URL: 'Marketing_plan.pdf',
        ETHICS: 'Codul de etică',
        ETHICS_URL: 'Codul_de_etica.pdf',
        QUALIFICATION: 'Calificări',
        QUALIFICATION_URL: 'Calificari.pdf',
        COPY_LINK: 'Salvați linkul dvs. de partener',
        CLOSED_MONTH: 'Luna închisă',
        LANG: 'RO',
        BONUSES: 'Bonusuri',
        ACCURED_BONUSES: 'Bonusurile acumulate',
        VOLUMES: 'Volume',
        PV: 'VP',
        GV: 'VG',
        CGV: 'VCG',
        PGV: 'VPG',
        FOR_11: 'pentru 11 luni',
        QUALIFICATIONS: 'Calificări',
        COUNTRY: 'Țara',
        TOP_SELLER : 'Cel mai bun vînzător',
        RECRUITMENT_LEADER: 'Lider de Recrutare',
        STABILITY: 'Stabilitate',
        NETWORK: 'Rețea',
        DISTRIBUTORS: 'Distribuitorilor',
        ACTIVE_DISTRIBUTORS: 'Distribuitori activi',
        QUALIFIED_DISTRIBUTORS: 'Distribuitori calificați',
        LO_MORE_0: '(V.P. – mai mare de 0 p)',
        PV_GREATER_25P: '(V.P. -mai mare sau egal cu 25 p)',
        QUALIFIED_DIRECTORS_1: 'Directori calificați în prima generație',
        DEPTH_LEVEL : 'Nivelul de profunzime al directorilor calificați',
        NEW_RECRUITS: 'Nou-veniții înscriși în prima linie',
        TOTAL_RECRUITS: 'Total nou-veniți',
        DOWNLOAD_BONUS: 'Descărcați bonusul tipărit',
        CURRENT_MONTH : 'Datele lunii curente',
        SIGNING_DATE : 'Data semnării',
        RANG: 'Rang',
        PRN: 'NPÎ',
        FULL_NAME: 'Nume Prenume',
        EMAIL: 'Poșta',
        PHONE: 'Telefon',
        VOLUME: 'Volum',
        NUMBER: 'Număr',
        PRICE: 'Preț',
        TOTAL_POINTS: 'Total puncte',
        TOTAL_ITEMS: 'Total produse',
        TOTAL_COST: 'Suma totală',
        POINTS: 'Puncte',
        NAME: 'Denumire',
        MAKEUP: 'Machiaj',
        PERFUMERY: 'Parfumerie',
        FILTERS: 'Filtre',
        QUANTITY: 'Cantitate',
        QUANTITY_HALF: 'Cant.',
        GOODS_1: 'Produsul este adăugat cu succes in coș',
        GOODS_2: 'Îngrijirea feței',
        GOODS_3: 'Produse fără puncte',
        GOODS_4: 'Sortare după:',
        GOODS_5: 'Preț crescator',
        GOODS_6: 'Preț descrescător',
        GOODS_7: 'Puncte în creștere',
        GOODS_8: 'Puncte în descreștere',
        GOODS_9: 'Rezultate nu-s...',
        GOODS_10: 'Lista produselor',
        EMPTY_CART: 'Coșul este gol...',
        PLACE_ORDER: 'Plasează comanda',
        CANCEL: 'Anulare',
        DELETE: 'Șterge',
        CART_1: 'Comanda este finalizată! Redirecționare spre pagina cu comanda...',
        CART_2: 'Eroare în timpul creării comenzei',
        CART_3: 'Sigur doriți să ștergeți articolul din coș?',
        GO_TO_CART: 'Treci la coș',
        CHOOSE: 'Alegeți...',
        NO_RESULTS: 'Rezultate nu-s...',
        LEARN_MORE: 'Află mai mult',
        MY_ORDERS: 'Comenzile mele',
        CURRENT: 'Curent',
        CANCELED: 'Anulat',
        CLOSED: 'Închise',
        CREATED_DATE: 'Data creării',
        QUANTITY_ITEMS: 'Numărul produselor',
        AMOUNT: 'Suma',
        STATUS: 'Status',
        UPDATE_DATE: 'Data actualizării',
        ORDER_NR: 'Comanda Nr.',
        LINK_PAYMENT: 'Link de plată',
        ONLINE_PAY: 'Achitare online',
        DISCOUNT: 'Reducerea',
        WAREHOUSE_NR: 'Nr depozitului',
        WAREHOUSE_TOTAL: 'Totalul depozitului',
        CUSTOMER: 'Client',
        DELETE_ORDER: 'Șterge comanda',
        WAREHOUSE_ORDER: 'Comenzile depozitului',
        ASK_FOR_DELIVERY: 'Solicitați livrare',
        REQUEST: 'Solicitați',
        PICKUP: 'Ridicare',
        CLIENT_MODE_DELIVERY: 'Partenerul selectează modul de livrare',
        DELIVERY_ADDRESS: 'Adresa de livrare',
        WAIT_FOR_WAREHOUSE_CONFIRMATION: 'Comanda în așteptarea confirmării de la depozit',
        CANCEL_ORDER: 'Anulare',
        NEW_ORDER_RECEIVE: 'Comanda nouă primită',
        ORDER_CANCELLED: 'Comanda anulată',
        ORDER_CANCELLED_DISTRIBUTOR: 'Comanda anulată de distribuitor',
        WAREHOUSE_REJECTED_ORDER: 'Depozit a respins comanda',
        ORDER_REJECTED: 'Comanda respinsă',
        CHECKING_ORDER: "Verificarea comenzii la depozit",
        CONFIRMATION_ORDER_AVAILABILITY: "Confirmarea disponibilitatății comenzii",
        CONFIRMED_AVAILABILITY: "Confirmarea disponibilitatății",
        CONFIRMED_PARTIAL_AVAILABILITY: "Confirmarea disponibilitatății parțială",
        REJECTED: "Respins",
        REJECTED_NEW: "Respinși",
        MESSAGES: 'Chat',
        WAREHOUSEASSIGNED: "Noi",
        SENDEDTOONEC: "Adăugați în 1C",
        SENDTOONEC: "Adăugat în 1C",
        TOBEREJECTED: "Respinge",
        WAREHOUSE_CONFIRMED_AVAILABILITY: "Depozitul a confimat disponibilitatea comenzii",
        CONFIRMED_ORDER_AVAILABILITY: "Disponibilitatea comenzii confirmată",
        WAREHOUSE_REJECT_ABSENCE_ITEM: "Depozitul a respins comanda din motivul lipsei de marfă",
        WAREHOUSE_REJECT_ABSENCE_ITEMS_TOTAL: "Comanda respinsă: lipsa totală de marfă",
        WAREHOUSE_CHANGED_ORDER: "Depozitul a anulat comanda din motivul lipsei de marfă",
        CONFIRMED: "Confirmă",
        WAITING_DISTRIBUTOR_CONFIRMATION: "Se așteaptă ca distribuitorul să confirme comanda modificată",
        NOT_COMPLETE_ORDER_CONFIRMED: "Comanda parțială confirmată",
        DISTRIBUTOR_CONFIRMED_DELIVERY: "Distribuitorul a confirmat camanda",
        ORDER_READY: "Comanda este gata",
        NOT_COMPLETE_ORDER_REJECTED:"Comanda parțială respinsă de distribuitor",
        NOT_COMPLETE_ORDER_CANCELED: "Comanda parțială anulată de distribuitor",
        ORDER_READY_DELIVERY: "Comanda este gata pentru ridicare",
        REQUESTED_DELIVERY: "Solicitați livrare",
        ORDER_READY_DELIVERY_PICKUP: "Comanda este gata pentru ridicare/livrare",
        CONFIRMED_DELIVERY_ORDER: "Confirmă ridicarea comenzii",
        WAREHOUSE_PREPARED_ORDER: "Depozitul a pregătit comanda",
        DISTRIBUTOR_ASK_PICKUP: "Distribuitorul a solicitat ridicare",
        CONFIRM_PICKUP: "Confirmă ridicarea",
        WAREHOUSE_PROCESSING_DELIVERY: "Depozitul pregătește comanda",
        DISTRIBUTOR_ASK_DELIVERY: "Distribuitorul a solicitat livrare",
        DELIVERY_PAYMENT_INFO: 'Plata livrării se efectuează separat de comandă',
        SENT: "Livrat",
        ORDER_SENT: "Comanda a fost livrată",
        CONFIRMED_DELIVERY: "Confirmă primirea",
        ORDER_DELIVERIES: "Comanda în proces de livrare",
        ORDER_COMPLETE: "Comanda finalizată",
        REASON: 'Motivul',
        ACCEPT: 'Acceptă',
        REJECT_THE_ORDER: 'Respinge comanda',
        EDITING_THE_ORDER: 'Redactarea comenzii',
        FINAL_COST: 'Prețul final',
        ONLINE_PAYMENT_OPTION:'Posibilitatea de achitare online',
        PAYMENT_LINK: 'Link de plată',
        ADDRESS: 'Adresa',
        EXIT: 'Ieșire',
        DONE: 'Executat',
        NEW: 'Nou',
        NEWERS: 'Începători',
        IS_WAITING_BY_WAREHOUSE: 'În așteptarea confirmării de la depozit',
        CANCELED_BY_WAREHOUSE: 'Anulat de depozit',
        ACCEPTED_WAREHOUSE: 'Acceptat de depozit',
        REJECTED_BY_WAREHOUSE: 'Respins de depozit',
        MODIFIED_BY_WAREHOUSE: 'Modificat de depozit',
        ACCEPTED_BY_DISTRIBUTOR: 'Acceptat de distribuitor',
        CANCELED_BY_DISTRIBUTOR: 'Anulat de distribuitor',
        READY_TO_PICKUP: 'Gata pentru ridicare',
        READY_TO_DELIVERY: 'Gata pentru livrare',
        SELF_PICKUP: 'Ridicare',
        IS_DELIVERED: 'În proces de livrare',
    },
    UA: {
        MAIN: 'Головна',
        MY_NETWORK: 'Моя Мережа',
        PRODUCTS: 'Товари',
        ORDERS: 'Мої Замовлення',
        ORDERS_WH: 'Заказы к складу',
        CART: 'Кошик',
        PROMOTIONS: 'Акції',
        PROMOTIONS_RATINGS: 'Акції та Рейтинги',
        MARKETING_PLAN: 'Маркетинг План',
        MARKETING_PLAN_URL: 'Маркетинг_план.pdf',
        ETHICS: 'Кодекс этики',
        ETHICS_URL: 'Кодекс_этики.pdf',
        QUALIFICATION: 'Квалификации',
        QUALIFICATION_URL: 'Квалификации.pdf',
        COPY_LINK: 'Скопіюйте ваше партнерське посилання',
        CLOSED_MONTH:'Закритий місяць',
        LANG: 'uk-UA',
        BONUSES: 'Бонуси',
        ACCURED_BONUSES: 'Нараховані бонуси',
        VOLUMES: 'Об‘єми',
        PV: 'ОО',
        GV: 'ГО',
        CGV: 'НГО',
        PGV: 'ООГ',
        FOR_11: 'за 11 місяців',
        QUALIFICATIONS: 'Кваліфікації',
        COUNTRY: 'Країна',
        TOP_SELLER : 'Кращий продавець',
        RECRUITMENT_LEADER: 'Лідер рекрутингу',
        STABILITY: 'Стабільність',
        NETWORK: 'Мережа',
        DISTRIBUTORS: 'Дистриб‘юторів',
        ACTIVE_DISTRIBUTORS: 'Активні дистриб‘ютори',
        QUALIFIED_DISTRIBUTORS: 'Кваліфіковані дистриб‘ютори',
        LO_MORE_0: '(О.О.- більше 0 б.)',
        PV_GREATER_25P: '(О.О. - більше або дорівнює 25 б.)',
        QUALIFIED_DIRECTORS_1: 'Кваліфікованих директорів в 1 поколінні',
        DEPTH_LEVEL : 'Рівень глибини кваліфікованих директорів',
        NEW_RECRUITS: 'Новачків підписаних в першу лінію',
        TOTAL_RECRUITS: 'Всього новачків',
        DOWNLOAD_BONUS: 'Завантажити бонус',
        CURRENT_MONTH : 'Данні поточного місяця',
        SIGNING_DATE : 'Дата підписання',
        RANG: 'Ранг',
        PRN: 'ОРН',
        FULL_NAME: 'ПІБ',
        EMAIL: 'Пошта',
        PHONE: 'Телефон',
        VOLUME: 'Об‘єм',
        NUMBER: 'Номер',
        PRICE: 'Ціна',
        QUANTITY: 'Кількість',
        QUANTITY_HALF: 'Кіл.',
        TOTAL_POINTS: 'Всього балів',
        TOTAL_ITEMS: 'Всього товарів',
        TOTAL_COST: 'Загальна вартість',
        POINTS: 'Бали',
        NAME: 'Назва',
        MAKEUP: 'Макіяж',
        PERFUMERY: 'Парфумерія',
        FILTERS: 'Фільтри',
        GOODS_1: 'Товар успішно доданий до кошика',
        GOODS_2: 'Догляд за обличчям',
        GOODS_3: 'Безбалові товари',
        GOODS_4: 'Сортувати за:',
        GOODS_5: 'Від дешевих до дорогих',
        GOODS_6: 'Від дорогих до дешевих',
        GOODS_7: 'Бали – за зростанням',
        GOODS_8: 'Бали - за спаданням',
        GOODS_9: 'Немає результатів...',
        GOODS_10: 'Список товарів',
        EMPTY_CART: 'Кошик пустий...',
        PLACE_ORDER: 'Оформити замовлення',
        CANCEL: 'Відмінити',
        DELETE: 'Видалити',
        CART_1: 'Замовлення виконане! Перенаправлення на сторінку замовлення…',
        CART_2: 'Помилка під час створення замовлення',
        CART_3: 'Ви впевнені, що бажаєте видалити товар із кошика?',
        GO_TO_CART: 'Перейти до кошику',
        CHOOSE: 'Оберіть...',
        NO_RESULTS: 'Немає результатів...',
        LEARN_MORE: 'Дізнатися більше',
        MY_ORDERS: 'Мої Замовлення',
        CURRENT: 'Поточні',
        CANCELED: 'Відмінене',
        CLOSED: 'Закриті',
        CREATED_DATE: 'Дата створення',
        QUANTITY_ITEMS: 'Кількість товарів',
        AMOUNT: 'Сума',
        STATUS: 'Статус',
        UPDATE_DATE: 'Дата оновлення',
        ORDER_NR: 'Замовлення Nr.',
        LINK_PAYMENT: 'Посилання на оплату',
        ONLINE_PAY: 'Оплата онлайн',
        DISCOUNT: 'Знижка',
        WAREHOUSE_NR: 'Номер складу',
        WAREHOUSE_TOTAL: 'Тотал складу',
        CUSTOMER: 'Замовник',
        DELETE_ORDER: 'Видалити замовлення',
        WAREHOUSE_ORDER: 'Замовлення складу',
        ASK_FOR_DELIVERY: 'Запит доставки',
        REQUEST: 'Підтвердити',
        PICKUP: 'Самовивіз',
        CLIENT_MODE_DELIVERY: 'Партнер обирає спосіб доставки',
        DELIVERY_ADDRESS: 'Адреса доставки',
        WAIT_FOR_WAREHOUSE_CONFIRMATION: 'Замовлення очікує підтвердження складом',
        CANCEL_ORDER: 'Відмінити',
        NEW_ORDER_RECEIVE: 'Нове замовлення отримане',
        ORDER_CANCELLED: 'Замовлення відмінити',
        ORDER_CANCELLED_DISTRIBUTOR: 'Замовлення відмінити дистриб\'ютором',
        WAREHOUSE_REJECTED_ORDER: 'Склад відхилив замовлення',
        ORDER_REJECTED: 'Замовлення відхилено',
        CHECKING_ORDER: "Перевірка наявності товарів на складі",
        CONFIRMATION_ORDER_AVAILABILITY: "Підтвердження наявності замовлення",
        CONFIRMED_AVAILABILITY: "Підтвердити наявність",
        CONFIRMED_PARTIAL_AVAILABILITY: "Підтвердити часткову наявність",
        REJECTED: "Відхилити",
        REJECTED_NEW: "Відхилені",
        WAREHOUSEASSIGNED: "Нові",
        SENDEDTOONEC: "Відправленi в 1C",
        SENDTOONEC: "Відправлено в 1C",
        TOBEREJECTED: "Відхилити",
        MESSAGES: 'Чат',
        WAREHOUSE_CONFIRMED_AVAILABILITY: "Склад підтвердив наявність товарів",
        CONFIRMED_ORDER_AVAILABILITY: "Підтверджено наявність товарів",
        WAREHOUSE_REJECT_ABSENCE_ITEM: "Склад відхилив замовлення через відсутність товару",
        WAREHOUSE_REJECT_ABSENCE_ITEMS_TOTAL: "Замовлення відхилено: повна відсутність",
        WAREHOUSE_CHANGED_ORDER: "Склад скасував замовлення через відсутність товару",
        CONFIRMED: "Підтвердити",
        WAITING_DISTRIBUTOR_CONFIRMATION: "Очікування підтвердження дистриб'ютором скасованого замовлення",
        NOT_COMPLETE_ORDER_CONFIRMED: "Не полный Заказ подтверджден",
        DISTRIBUTOR_CONFIRMED_DELIVERY: "Дистриб'ютор підтвердив заказ",
        ORDER_READY: "Заказ готов",
        NOT_COMPLETE_ORDER_REJECTED:"Не повне замовлення підтверджено",
        NOT_COMPLETE_ORDER_CANCELED: "Часткове замовлення скасовано дистриб'ютором",
        ORDER_READY_DELIVERY: "Замовлення готове до видачі",
        REQUESTED_DELIVERY: "Замовити доставку",
        ORDER_READY_DELIVERY_PICKUP: "Замовлення готове до видачі/доставки",
        CONFIRMED_DELIVERY_ORDER: "Підтвердити видачу замовлення",
        WAREHOUSE_PREPARED_ORDER: "Склад підготував замовлення",
        DISTRIBUTOR_ASK_PICKUP: "Дистриб‘ютор запросив самовивіз",
        CONFIRM_PICKUP: "Підтвердити самовивіз",
        WAREHOUSE_PROCESSING_DELIVERY: "Склад оформлює доставку",
        DISTRIBUTOR_ASK_DELIVERY: "дистриб‘ютор запросив доставку",
        DELIVERY_PAYMENT_INFO: 'Оплата доставки здійснюється окремо від замовлення',
        SENT: "Відправлено",
        ORDER_SENT: "Замовлення відправлено",
        CONFIRMED_DELIVERY: "Підтвердити отримання",
        ORDER_DELIVERIES: "Замовлення доставляється",
        ORDER_COMPLETE: "Замовлення завершено",
        REASON: 'Причина',
        ACCEPT: 'Прийняти',
        REJECT_THE_ORDER: 'Відхилити замовлення',
        EDITING_THE_ORDER: 'Редагування замовлення',
        FINAL_COST: 'Загальна вартість',
        ONLINE_PAYMENT_OPTION:'Можливість оплати онлайн',
        PAYMENT_LINK: 'Посилання на оплату',
        ADDRESS: 'Адреса',
        EXIT: 'Вийти',
        DONE: 'Виконано',
        NEW: 'Новий',
        NEWERS: 'Новіші',
        IS_WAITING_BY_WAREHOUSE: 'Очікування складу',
        CANCELED_BY_WAREHOUSE: 'Відмінено складом',
        ACCEPTED_WAREHOUSE: 'Підтверджене складом',
        REJECTED_BY_WAREHOUSE: 'Відхилене складом',
        MODIFIED_BY_WAREHOUSE: 'Змінено складом',
        ACCEPTED_BY_DISTRIBUTOR: 'Прийняте дистриб‘ютором',
        CANCELED_BY_DISTRIBUTOR: 'Відмінене дистриб‘ютором',
        READY_TO_PICKUP: 'Готове до видачі',
        READY_TO_DELIVERY: 'Готове до відправлення',
        SELF_PICKUP: 'Самовивіз',
        IS_DELIVERED: 'Доставляється',
    },
    IT: {
        MAIN: 'Principale',
        MY_NETWORK: 'La mia rete',
        PRODUCTS: 'Prodotti',
        ORDERS: 'I miei ordini',
        ORDERS_WH: 'Ordini al deposito',
        CART: 'Carrello',
        PROMOTIONS: 'Promozioni',
        PROMOTIONS_RATINGS: 'Promozioni e Valutazioni',
        MARKETING_PLAN: 'Piano di marketing',
        // MARKETING_PLAN_URL: 'Piano_di_marketing.pdf',
        MARKETING_PLAN_URL: 'Маркетинг_план.pdf',
        ETHICS: 'Codice etico',
        ETHICS_URL: 'Codice_etico.pdf',
        QUALIFICATION: 'Qualifiche',
        QUALIFICATION_URL: 'Qualifiche.pdf',
        COPY_LINK: 'Copia il tuo link di affiliazione',
        CLOSED_MONTH:'Mese chiuso',
        LANG: 'IT',
        BONUSES: 'Bonus',
        ACCURED_BONUSES: 'Bonus accumulato',
        VOLUMES: 'Volumi',
        PV: 'VP',
        GV: 'VG',
        CGV: 'VGA',
        PGV: 'VGP',
        FOR_11: 'per 11 mesi',
        QUALIFICATIONS: 'Qualifiche',
        COUNTRY: 'Paese',
        TOP_SELLER : 'Miglior venditore',
        RECRUITMENT_LEADER: 'Leader del reclutamento',
        STABILITY: 'Stabilita',
        NETWORK: 'Rete',
        DISTRIBUTORS: 'Distributori',
        DISTRIBUTOR: 'Distributore',
        ACTIVE_DISTRIBUTORS: 'Distributori attivi',
        QUALIFIED_DISTRIBUTORS: 'Distributori qualificati',
        LO_MORE_0: '(V.P. - più di 0)',
        PV_GREATER_25P: '(V.P.- maggiore o uguale a 25 unità)',
        QUALIFIED_DIRECTORS_1: ' Distributori qualificati in prima generazione',
        DEPTH_LEVEL : 'Livello di profondità degli direttori qualificati',
        NEW_RECRUITS: 'Nuovi reclutati nella prima linea',
        TOTAL_RECRUITS: 'Totale reclutati',
        DOWNLOAD_BONUS: 'Scarica il bonus',
        CURRENT_MONTH : 'Dati del mese corrente',
        SIGNING_DATE : 'Data di firma',
        RANG: 'Grado',
        PRN: 'NRP',
        FULL_NAME: 'Nome completo',
        EMAIL: 'Email',
        PHONE: 'Telefono',
        VOLUME: 'Volume',
        NUMBER: 'Numero',
        PRICE: 'Prezzo',
        QUANTITY: 'Quantità',
        QUANTITY_HALF: 'Quan.',
        TOTAL_POINTS: 'Punti totali',
        TOTAL_ITEMS: 'Totale articoli',
        TOTAL_COST: 'Costo totale',
        POINTS: 'Punti',
        NAME: 'Nome',
        MAKEUP: 'Trucco',
        PERFUMERY: 'Profumeria',
        FILTERS: 'Filtri',
        GOODS_1: 'Prodotto aggiunto con successo al carrello',
        GOODS_2: 'Cura del viso',
        GOODS_3: 'prodotti senza unità',
        GOODS_4: 'Ordinare per:',
        GOODS_5: 'Prezzo - crescente',
        GOODS_6: 'Prezzo - decrescente',
        GOODS_7: 'Punti - crescente',
        GOODS_8: 'Punti - decrescente',
        GOODS_9: 'Nessun risultato..',
        GOODS_10: 'Elenco dei prodotti',
        EMPTY_CART: 'Il carrello è vuoto...',
        PLACE_ORDER: 'Effettua un ordine',
        CANCEL: 'Annulla',
        DELETE: 'Elimina',
        CART_1: 'Ordine completato! Reindirizzamento alla pagina dell’ordine...',
        CART_2: 'Errore durante la creazione dell\'ordine',
        CART_3: 'Sei sicuro di voler cancellare il prodotto dal carrello?',
        GO_TO_CART: 'vai al carrello',
        CHOOSE: 'Scegliere...',
        NO_RESULTS: 'Nessun risultato...',
        LEARN_MORE: 'Scopri di più',
        MY_ORDERS: 'I miei ordini',
        CURRENT: 'Сorrenti ',
        CANCELED: 'Annulati',
        CLOSED: 'Chiusi',
        CREATED_DATE: 'Data di creazione',
        QUANTITY_ITEMS: 'Quantità di articoli',
        AMOUNT: 'Importo',
        STATUS: ' Stato',
        UPDATE_DATE: 'Data di aggiornamento',
        ORDER_NR: 'Ordine Nr.',
        LINK_PAYMENT: 'Link per il pagamento',
        ONLINE_PAY: 'Pagamento online',
        DISCOUNT: 'Sconto',
        WAREHOUSE_NR: 'Numero al deposito',
        WAREHOUSE_TOTAL: 'Totale al deposito',
        CUSTOMER: 'Cliente',
        DELETE_ORDER: 'cancellare ordine',
        WAREHOUSE_ORDER: 'Ordine al deposito',
        ASK_FOR_DELIVERY: 'Richiedere la consegna',
        REQUEST: 'Richiedere',
        PICKUP: 'Ritiro personale dal deposito',
        CLIENT_MODE_DELIVERY: 'Il partner sceglie il metodo di consegna',
        DELIVERY_ADDRESS: 'l’indirizzo per la consegna',
        WAIT_FOR_WAREHOUSE_CONFIRMATION: 'Ordine in attesa di conferma dal magazzino',
        REVERT: 'Annulla',
        CANCEL_ORDER: 'Annulla ordine',
        NEW_ORDER_RECEIVE: 'Nuovo ordine ricevuto',
        ORDER_CANCELLED: 'Ordine annulati',
        ORDER_CANCELLED_DISTRIBUTOR: 'Ordine annulati per distributore',
        WAREHOUSE_REJECTED_ORDER: 'Ordine rifiutato dal magazzino',
        ORDER_REJECTED: 'Ordine rifiutato',
        CHECKING_ORDER: "Verifica dell'ordine in magazzino",
        CONFIRMATION_ORDER_AVAILABILITY: "Conferma della disponibilità dell'ordine",
        CONFIRMED_AVAILABILITY: "Confermare disponibilità",
        CONFIRMED_PARTIAL_AVAILABILITY: "Confermare disponibilità parziale",
        REJECTED: "Rifiutato",
        REJECTED_NEW: "Rifiutato",
        WAREHOUSEASSIGNED: "Nuovi",
        SENDEDTOONEC: "Aggiunto in 1С",
        SENDTOONEC: "Aggiunto in 1С",
        TOBEREJECTED: "Rifiutare",
        MESSAGES: 'Chat',
        WAREHOUSE_CONFIRMED_AVAILABILITY: "Magazzino confermato la disponibilità dell'ordine",
        CONFIRMED_ORDER_AVAILABILITY: "Disponibilità ordine confermata",
        WAREHOUSE_REJECT_ABSENCE_ITEM: "Ordine rifiutato dal magazzino per mancanza del prodotto",
        WAREHOUSE_REJECT_ABSENCE_ITEMS_TOTAL: "Ordine rifiutato: mancanza totale",
        WAREHOUSE_CHANGED_ORDER: "Ordine modificato dal magazzino per mancanza del prodotto",
        CONFIRMED: "Confermare",
        WAITING_DISTRIBUTOR_CONFIRMATION: "In attesa di conferma dal distributore per l'ordine modificato",
        NOT_COMPLETE_ORDER_CONFIRMED: "Ordine non completo confermato",
        DISTRIBUTOR_CONFIRMED_DELIVERY: "Ordine confermato dal distributore",
        ORDER_READY: "Ordine pronto",
        NOT_COMPLETE_ORDER_REJECTED:"Ordine non completo rifiutato dal distributore",
        NOT_COMPLETE_ORDER_CANCELED: "Ordine parziale annullato dal distributore",
        ORDER_READY_DELIVERY: "Ordine è pronto per il rilascio",
        REQUESTED_DELIVERY: "Richiesta consegna",
        ORDER_READY_DELIVERY_PICKUP: "Ordine è pronto per il rilascio/ consegna",
        CONFIRMED_DELIVERY_ORDER: "Conferma consegna dell'ordine",
        WAREHOUSE_PREPARED_ORDER: "Ordine preparato dal magazzino",
        DISTRIBUTOR_ASK_PICKUP: "Il distributore ha richiesto il ritiro in negozio",
        CONFIRM_PICKUP: "Conferma ritiro",
        WAREHOUSE_PROCESSING_DELIVERY: "Il magazzino sta elaborando la consegna",
        DISTRIBUTOR_ASK_DELIVERY: " il distributore ha richisto la consegna ",
        DELIVERY_PAYMENT_INFO: 'Il pagamento della consegna viene effettuato separatamente dall\'ordine',
        SENT: "Inviato",
        ORDER_SENT: "Ordine inviato",
        CONFIRMED_DELIVERY: "Conferma ricezione",
        ORDER_DELIVERIES: "Ordine in consegna",
        ORDER_COMPLETE: "Ordine completato",
        REASON: 'Motivo',
        ACCEPT: 'Accetta',
        REJECT_THE_ORDER: 'Rifiuta l\'ordine',
        EDITING_THE_ORDER: 'Modifica dell\'ordine',
        FINAL_COST: 'Costo finale',
        ONLINE_PAYMENT_OPTION:'Opzione di pagamento online',
        PAYMENT_LINK: 'Link per il pagamento',
        ADDRESS: ' Indirizzo',
        EXIT: 'Esci',
        DONE: 'Completato',
        NEW: 'Nuovo',
        NEWERS: 'Nuovi arrivati',
        IS_WAITING_BY_WAREHOUSE: 'In attesa del magazzino',
        CANCELED_BY_WAREHOUSE: 'annulati al deposito ',
        ACCEPTED_WAREHOUSE: 'Accettato dal magazzino',
        REJECTED_BY_WAREHOUSE: 'Rifiutato dal deposito',
        MODIFIED_BY_WAREHOUSE: 'Cambiato dal deposito',
        ACCEPTED_BY_DISTRIBUTOR: 'Accettato il distributore',
        CANCELED_BY_DISTRIBUTOR: 'Annulati il distributore',
        READY_TO_PICKUP: 'pronto per il rilascio',
        READY_TO_DELIVERY: 'pronto per la consegna',
        SELF_PICKUP: 'Ritiro personale dal deposito',
        IS_DELIVERED: 'In viaggio',
    },
}