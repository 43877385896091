import React from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const Carousel = ({ images }) => {

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1500,
        autoplaySpeed: 2500
    };
    return (
        <Slider {...settings}>
            {images.map((step) => (
                <div key={step.id}>
                    <img
                        src={step.imgPath}
                        alt={step.label}
                    />

                </div>
            ))
            }
        </Slider>
    );
};

export default Carousel;
